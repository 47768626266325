export const getUser = () => {
   const userStr = localStorage.getItem("user");
   if (userStr) return JSON.parse(userStr);
   else return null;
}

export const getToken = () => {
    const pre_time = localStorage.getItem("time");
    const now = new Date().getTime();
    if (now > pre_time) {
       return null; 
    } else {
        return localStorage.getItem("token") || null;
    }
    
    
}

export const setUserSession = (token, user) => {
    const minutes = 30;
    const now = new Date().getTime() + (minutes * 60 * 1000); 
    //for hour
    // const hours = 24;
    // const now = new Date().getTime() + ( hours*60*60*1000);
    localStorage.setItem("token", token);
    localStorage.setItem("user",JSON.stringify(user));
    localStorage.setItem("time",now);
}

export const removeUserSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("time");
}