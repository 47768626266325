import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/uda-logo.png";
import { setUserSession } from "../Utils/Common";
import axios from "axios";

function Login(props){

    const [username, setUsername] = useState('');
    const [password, setUserpassword] = useState('');
    const [unerror, setUNError] = useState(null);
    const [pwerror, setPWError] = useState(null);
    const [error, setError] = useState(null);

    const formValidaion = () => {
        let require_field = false;
        setUNError(null);
        setPWError(null);
        setError(null);
        if (username === '') {
            setUNError('This field is required');
            require_field = true;
        } 
        if (password === '') {
            setPWError('This field is required');
            require_field = true;
        }
        return require_field;
    }

    const handleLogin = (e) => {
        e.preventDefault();
        const isValid = formValidaion();
        if (isValid) {
            return false;
        } else {
            setError(null);  
            let form = new FormData();
            form.append('authenticationkey','123456');
            form.append('username',username);
            form.append('password',password);
            axios({
                method: 'post',
                url: 'https://udainpy.in/api-login/',
                data: form
            })
            .then((response) => {
                if (response.data.status === 'success') {
                    let data_token = response.data.data.api_random_key;
                    let store_data = {id: response.data.data.id, email: response.data.data.email,name: response.data.data.name,api_random_key:response.data.data.api_random_key};
                    setUserSession(data_token, store_data);
                    props.history.push('/spring-form');
                    console.log(store_data);
                } else {
                    setError(response.data.msg) ;
                    return false;
                }
            });
                 //setUserSession(data_token, data);
                // console.log('success');
                 //props.history.push('/spring-form');
        }
    }

    return(
        <div>
           <div className="signup-form col-sm-10 col-centered">
                <h2>
                    <Link to="">
                    <img src={logo} alt=""></img>
                    </Link>
                </h2>
            </div>

            <div className="new-bg">
                <div className="row text-center">
                    <div className="col-sm-12 col-xs-12" style={{display:'none'}}>
                        <div className="form-group">
                            <Link>
                               <h3 className="your_info">UDA CONVENTION</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-xs-12">
                        <div className="form-group">
                           <h3 className="your_info">UDA CONVENTION</h3>
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12"></div>
                    <div className="col-sm-4 col-xs-12">
                        <form>
                            <label>Username</label>
                            <input 
                             type="text"
                             className="form-control"
                             name="usrname"
                             value={username}
                             onChange={e => setUsername(e.target.value)}
                             required
                            />
                            {unerror && <div className="loginerror">{unerror}</div> }
                            <label>Password</label>
                            <input 
                             type="password"
                             className="form-control"
                             name="psw"
                             value={password}
                             onChange={e => setUserpassword(e.target.value)}
                             required
                            />
                            {pwerror && <div className="loginerror">{pwerror}</div> }
                            <br />
                            {error && <div className="loginerror">{error}</div> }
                            <input 
                             type="button"
                             name="sub"
                             value="Access Form"
                             onClick={handleLogin}
                            />
                        </form>
                    </div>
                    <div className="col-sm-4 col-xs-12"></div>
                </div>
            </div>


        </div>
        
    )
}

export default Login;