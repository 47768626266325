import React, { useEffect }  from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SpringForm from "./routes/SpringForm";
import NotFound from "./NotFound";
import ConventiononPayment from "./routes/ConventiononPayment";
import ConventionTransaction from "./routes/ConventionTransaction";
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/Login";
import PublicRoute from "./Utils/PublicRoute";
import PrivateRoute from "./Utils/PrivateRoute";
import { getToken, getUser, removeUserSession, setUserSession } from "./Utils/Common";

function App() {

    //const [authLoading, setAuthLoding] = useState(true);
    //const token = getToken();

    useEffect(() => {
        const token = getToken();
        if (!token) {
            return;
        }
        
        if (token) {
            //setAuthLoding(false);
            let data_token = token;
            let data  = getUser();
            setUserSession(data_token, data);
        } else {
            removeUserSession();
            //setAuthLoding(false);
        }
    }, []);

    return (
        <div className="main-bg only-this-input">
            {/* basename="" - THIS IS FOR FOLDER */}
           <BrowserRouter >
                  <Switch>
                      <PublicRoute exact path="/" component={Login} />
                      <PrivateRoute path="/spring-form" component={SpringForm} />
                      <PrivateRoute path="/convention-on-payment" component={ConventiononPayment} />
                      <PrivateRoute path="/convention-on-transaction" component={ConventionTransaction} />
                      <Route component={NotFound} />
                  </Switch>
           </BrowserRouter>
        </div>
    )
}
export default App;