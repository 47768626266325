import React, { useEffect, useState }  from "react";
import ReactInputMask from "react-input-mask";
import logo from "../images/uda-logo.png";
import validator from "validator";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const coventionList = [
    {
        id: 18,
        name: "UDA MEMBER DENTIST NAME",
        status: "1",
        form_status: "2",
        price: "95"
    },
    {
        id: 19,
        name: "NON-UDA/ADA DENTIST NAME",
        status: "1",
        form_status: "2",
        price: "295"
    },
    {
        id: 20,
        name: "UDA DENTAL HYGIENIST AFFILIATE MEMBER NAME",
        status: "1",
        form_status: "2",
        price: "25"
    },
    {
        id: 21,
        name: "NON-UDA AFFILIATE HYGIENIST NAME",
        status: "1",
        form_status: "2",
        price: "100"
    }
];

function SpringForm(props) {

    const [practice_name, setPracticename] = useState('');
    const [name, setName] = useState('');
    const [lname, setLname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [statename, setStatename] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [pnerr, setPnerr] = useState(null);
    const [nerr, setNerr] = useState(null);
    const [lerr, setLerr] = useState(null);
    const [pherr, setPherr] = useState(null);
    const [emailerr, setEmailerr] = useState(null);
    const [adderr, setAdderr] = useState(null);
    const [cityerr, setCityerr] = useState(null);
    const [serr, setSerr] = useState(null);
    const [zerr, setZerr] = useState(null);
    const [conventdata, setconventData] = useState([]);
    const [req_id, setreq_id] = useState('');
    const [count, setCount] = useState({
        count: {
           0: {},
           1: {},
           2: {},
           3: {},
        }
     });
    const [totalgrand, setTotalgrand] = useState(0);
    

    useEffect(() => {
        document.title = "SPRING REGISTRATION FORM"
        window.scrollTo(0, 0)
        setconventData(coventionList); 
        setreq_id(''); 
        setTotalgrand(0);
        
        for (let i = 0; i < coventionList.length; i++) {
            if (coventionList[i]['id'] > 0) {
                count.count[i].qty = 1;
                count.count[i].is_enter = 0;
                count.count[i].grand = Number(coventionList[i]['price']);
                count.count[i].grand_tot = 0;
                setCount({
                ...count
                });       
              }  
        }
         //eslint-disable-next-line
    }, []);
    console.log('data',count)
    const changevalue = (e) => { 
        let nam = e.target.name;
        let val = e.target.value;
        
        if (nam === 'practice_name' ) {
           val = val.replace(/[^a-zA-Z\s]/g,'');
           setPracticename(val);
           setPnerr(null);
        }
        if (nam === 'name') {
            val = val.replace(/[^a-zA-Z\s]/g,'');
            setName(val);
            setNerr(null);
        }
        if (nam === 'lname') {
            val = val.replace(/[^a-zA-Z\s]/g,'');
            setLname(val);
            setLerr(null);
        }
        if (nam === 'phone') {
            setPhone(val);
            setPherr(null);
        }
        if (nam === 'email') {
            setEmail(val);
            if (validator.isEmail(val)) {
                setEmailerr(null)
            } else {
                setEmailerr('Please enter a valid email address');
            }
        }
        if (nam === 'address') {
            setAddress(val);
            setAdderr(null);
        }
        if (nam === 'city') {
            setCity(val);
            setCityerr(null);
        }
        if (nam === 'statename') {
            setStatename(val);
            setSerr(null);
        }
        if (nam === 'zipcode') {
            val = val.replace(/[^0-9.]/g, '');
            setZipcode(val);
            setZerr(null);
        }
        
    }

   const formValidation = () => {
        let isValid = true;
        if (!practice_name) {
            setPnerr('This field is required.');
            // errors.practice_err_cls = 'error';
            isValid = false;
        }
        if (!name) {
            setNerr('This field is required.');
            isValid = false;
        }
        if (!lname) {
            setLerr('This field is required.');
            isValid = false;
        }
        if (!phone) {
            setPherr('This field is required.');
            isValid = false;
        }
        if (!email) {
            setEmailerr('This field is required.');
            isValid = false;
        }
        if (email) {
            if (validator.isEmail(email)) {
                setEmailerr('');
            } else {
                setEmailerr('This field is required.');
                isValid = false;
            }
        }
        if (!address) {
            setAdderr('This field is required.');
            isValid = false;
        }
        if (!city) {
            setCityerr('This field is required.');
            isValid = false;
        }
        if (!statename) {
            setSerr('This field is required.');
            isValid = false;
        }
        if (!zipcode) {
            setZerr('This field is required.');
            isValid = false;
        }
        return isValid;
        
    }

    const formsubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            props.history.push('/convention-on-payment');
        } else {
            window.scrollTo(0, 0)
        }
        
    }

    const removeDiv = (removeid, index_t, main_index) => {
        // document.getElementsByClassName('textbox-'+removeid+'-'+index_t)[0].remove();
        let reduce_co = count.count[main_index].qty - 1;
        count.count[main_index].qty = reduce_co;
        count.count[main_index].grand = Number(conventdata[main_index].price) * Number(reduce_co);
        count.count[main_index].grand_tot = Number(conventdata[main_index].price) * Number(reduce_co);
        setCount({
          ...count
        });
        let gtot1 = 0;
         for (let gt = 0; gt < conventdata.length; gt++) {
             gtot1 += count.count[gt].grand_tot;
             
         } 
         setTotalgrand(gtot1);
    }

    const countAdd = (e) => { 
         //let countname = e.target.name;
         let countval = e.target.value;
         let attr_id = e.target.getAttribute("data-id");
         countval = countval.replace(/[^0-9.]/g, '');
         countval = (countval==='') ? '' : countval;
         count.count[attr_id].qty = Number(countval);
         count.count[attr_id].is_enter = 1;
         let grand_cal = Number(conventdata[attr_id].price) * Number(countval);
         count.count[attr_id].grand = grand_cal;
         count.count[attr_id].grand_tot = grand_cal;
         setCount({
         ...count
         });
         let gtot = 0;
         for (let g = 0; g < conventdata.length; g++) {
             gtot += count.count[g].grand_tot;
             
         }
         setTotalgrand(gtot);
       
    }
     
    return (
        <div className="signup-form col-sm-10 col-centered">
            <form className="register-form" onSubmit={formsubmit}>
                <h2>
                    <Link to="spring-form">
                       <img src={logo} alt="" />
                    </Link>
                    SPRING REGISTRATION FORM</h2>
                <div className="new-bg">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <section className="wizard-section">
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-wizard">
                                            <div className="form-wizard-header">
                                                <ul className="list-unstyled form-wizard-steps clearfix">
                                                <li className="active"> 
                                                    <span className="large-src"> 
                                                        <svg viewBox="-27 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m146 492c0 11.046875-8.953125 20-20 20h-46c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h245.890625c44.109375 0 80 35.886719 80 80v119c0 11.046875-8.957031 20-20 20-11.046875 0-20-8.953125-20-20v-119c0-22.054688-17.945313-40-40-40h-245.890625c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h46c11.046875 0 20 8.953125 20 20zm179.886719-352c0-11.046875-8.953125-20-20-20h-206c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h206c11.046875 0 20-8.953125 20-20zm-79.886719 80c0-11.046875-8.953125-20-20-20h-126.113281c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h126.113281c11.046875 0 20-8.953125 20-20zm-146.113281 60c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h83.113281c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20zm348.921875 217.121094c-7.695313 9.457031-19.050782 14.878906-31.15625 14.878906h-195.304688c-12.171875 0-23.5625-5.417969-31.25-14.863281-7.734375-9.503907-10.738281-21.851563-8.242187-33.875 6.460937-31.125 23.574219-59.445313 48.183593-79.746094 9.320313-7.6875 19.5625-14.085937 30.421876-19.085937-12.101563-13.746094-19.460938-31.757813-19.460938-51.464844 0-43.011719 34.988281-78 78-78s78 34.988281 78 78c0 19.707031-7.359375 37.71875-19.460938 51.464844 10.859376 5 21.101563 11.394531 30.421876 19.085937 24.117187 19.894531 41.03125 47.492187 47.78125 77.878906.15625.527344.289062 1.058594.402343 1.601563 2.507813 12.09375-.53125 24.53125-8.335937 34.125zm-166.808594-184.15625c0 20.953125 17.046875 38 38 38s38-17.046875 38-38-17.046875-38-38-38-38 17.046875-38 38zm135.988281 158.46875c-.003906-.011719-.007812-.027344-.007812-.039063-9.605469-46.253906-50.8125-79.828125-97.980469-79.828125s-88.378906 33.574219-97.980469 79.828125c-.089843.449219.21875.59375.21875.597657.007813 0 .042969.007812.109375.007812h195.304688c.011718-.011719.28125-.214844.335937-.566406zm0 0"></path>
                                                        </svg>
                                                    </span> 
                                                    <span className="sml-src" style={{display:'none'}}>1</span>
                                                    <p>Details</p>
                                                </li>
                                                <li className=""> <span className="large-src"> 
                                                        <svg viewBox="0 -68 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m412 232.121094c11.046875 0 20 8.953125 20 20v24c0 11.046875-8.953125 20-20 20h-46c-11.046875 0-20-8.953125-20-20v-24c0-11.046875 8.953125-20 20-20zm60-36v-60h-432v159.878906c0 22.054688 17.945312 40 40 40h352c22.054688 0 40-17.945312 40-40 0-11.046875 8.953125-20 20-20s20 8.953125 20 20c0 44.113281-35.886719 80-80 80h-352c-44.113281 0-80-35.886719-80-80v-216c0-44.113281 35.886719-80 80-80h352c44.113281 0 80 35.886719 80 80v116.121094c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20zm0-100v-16.121094c0-22.054688-17.945312-40-40-40h-352c-22.054688 0-40 17.945312-40 40v16.121094zm-372 80c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h165c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20zm0 0"></path>
                                                        </svg>
                                                        </span> <span className="sml-src" style={{display:'none'}}>2</span>
                                                        <p>Review &amp; Payment</p>
                                                    </li>
                                                    <li className="">
                                                        <span className="large-src">
                                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{background:'new 0 0 512 512'}} space="preserve">
                                                        <g>
                                                            <g>
                                                            <g>
                                                                <path d="M468.04,91.687l-205-90c-5.147-2.26-11.007-2.249-16.146,0.029l-203,90C36.663,94.922,32,102.089,32,110v158
                                                                                c0,59.712,23.597,120.752,64.741,167.468c20.695,23.498,44.553,42.092,70.912,55.266C195.887,504.845,225.611,512,256,512
                                                                                c42.552,0,83.967-13.773,119.769-39.829c8.931-6.5,10.902-19.009,4.402-27.94c-6.5-8.931-19.009-10.901-27.94-4.402
                                                                                C323.313,460.875,290.037,472,256,472c-96.299,0-184-97.234-184-204V123.01l183.04-81.15L440,123.062V268
                                                                                c0,36.829-10.58,74.1-30.597,107.782c-5.643,9.496-2.52,21.768,6.976,27.411c9.497,5.643,21.768,2.52,27.411-6.976
                                                                                C467.479,356.354,480,312.018,480,268V110C480,102.064,475.307,94.878,468.04,91.687z"></path>
                                                                <path d="M351.385,157.503L227.379,312.451l-69.515-66.865c-7.961-7.657-20.622-7.412-28.279,0.549
                                                                                c-7.657,7.961-7.412,20.622,0.549,28.279l70.485,67.798c0.028,0.027,0.057,0.054,0.085,0.082
                                                                                c7.299,6.939,16.746,10.707,26.754,10.707c0.537,0,1.074-0.011,1.612-0.033c10.601-0.426,20.337-5.015,27.415-12.919
                                                                                c0.246-0.275,0.484-0.557,0.715-0.845l125.415-156.708c6.902-8.624,5.505-21.21-3.118-28.112
                                                                                C370.872,147.482,358.287,148.878,351.385,157.503z"></path>
                                                            </g>
                                                            </g>
                                                        </g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        </svg>
                                                        </span>
                                                        <span className="sml-src" style={{display:'none'}}>3</span>
                                                        <p>Confirmation</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="form-group">
                                <h3 className="your_info">Your Personal Info</h3>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>
                                    <h5>Practice Name :</h5>
                                </label>
                                <input 
                                    type="text"
                                    name="practice_name"
                                    placeholder="Practice Name"
                                    maxLength={100}
                                    onChange={e => changevalue(e)}
                                    value={practice_name}
                                    className=""
                                />
                                <label className="error">{pnerr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>First Name :</label>
                                <input 
                                    type="text"
                                    name="name"
                                    placeholder="First Name"
                                    maxLength={45}
                                    onChange={e => changevalue(e)}
                                    value={name}
                                    className=""
                                />
                                <label className="error">{nerr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Last Name :</label>
                                <input 
                                    type="text"
                                    name="lname"
                                    placeholder="Last Name"
                                    maxLength={45}
                                    onChange={e => changevalue(e)}
                                    value={lname}
                                    className=""
                                />
                                <label className="error">{lerr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label >Phone :</label>
                                <ReactInputMask 
                                mask="(999) 999-9999"
                                placeholder="Phone No"
                                name="phone"
                                onChange={e => changevalue(e)}
                                className=""
                                value={phone}
                                />
                                <label className="error">{pherr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Email :</label>
                                <input 
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    maxLength={200}
                                    className=""
                                    onChange={e => changevalue(e)}
                                    value={email}
                                />
                                <label className="error" >{emailerr}</label>
                            </div>
                        </div>

                        <div className="col-sm-8 col-xs-12">
                            <div className="form-group">
                                <label >Address :</label>
                                <input 
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    maxLength={500}
                                    onChange={e => changevalue(e)}
                                    value={address}
                                    className=""
                                />
                                <label className="error">{adderr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>City :</label>
                                <input 
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    maxLength={60}
                                    onChange={e => changevalue(e)}
                                    value={city}
                                    className=""
                                />
                                <label className="error">{cityerr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>State :</label>
                                <input 
                                    type="text"
                                    name="statename"
                                    placeholder="State"
                                    maxLength={60}
                                    onChange={e => changevalue(e)}
                                    value={statename}
                                    className=""
                                />
                                <label className="error">{serr}</label>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label >Zip :</label>
                                <input 
                                    type="text"
                                    name="zipcode"
                                    placeholder="Zip"
                                    maxLength={6}
                                    onChange={e => changevalue(e)}
                                    value={zipcode}
                                    className=""
                                />
                                <label className="error">{zerr}</label>
                            </div>
                        </div>

                        <div className="new-bg-note" style={{width:'100%'}}>
                            <label >Notes :</label>
                            <ul>
                                <li>Lunch at Courtyard by Marriott is included in the registration fee.</li>
                                <li>Deadline to register is April 10, 2021.</li>
                                <li>No refunds after April 10, 2021.</li>
                                <li>Confirmation will be emailed, but nametags will be at registration for pickup.</li>
                            </ul>
                        </div>


                    </div>
                </div>

                { 
                  conventdata.map((record, index) => (
                    <div>
                      <div className="new-bg sp">
                          {
                              (index === 0)
                              ?
                              <div className="row">
                                    <div className="text-center alert alert-info" style={{width:'100%',fontSize:'16px',marginBottom:'10px'}}>
                                    If you would like to add more than one person to any category please enter the number first to populate the same fields 
                                    </div>
                              </div>
                              :
                              ''
                          }
                          
                          
                            <div className="row">
                                <div className="col-sm-8 col-xs-12">
                                    <h4 className="new-title">
                                    {record.name}
                                    <span> (${record.price})</span>
                                    </h4>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <div className="form-group bt-down">
                                        <input 
                                        type="text"
                                        name={"convensionType["+record.id+"][qty]"}
                                        className="input2 choose-box-input input-box-sub-18"
                                        placeholder="Count"
                                        onChange={e=>countAdd(e)}
                                        id={record.id}
                                        data-id={index}
                                        value={(req_id === '' && count.count[index].is_enter === 0) ? '' : count.count[index].qty}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <div className="form-group mint">
                                        <div className="form-group">
                                            <div className="input-icon">
                                                <input 
                                                type="text"
                                                name=""
                                                className="row-amount form-control output cal input-box-amt-18 valid"
                                                value={(req_id === '' && count.count[index].is_enter === 0) ? 0 : count.count[index].grand}
                                                readOnly
                                                />
                                                <i>$</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="new-border"></div>

                            <div className={"text-box-"+record.id} id={count.count[index].qty}>
                           
                                    {
                                      (() => {
                                        const type_html = [];
                                        for (let t = 0; t < count.count[index].qty; t++) {
                                            if (record.id === 18 || record.id === 19) {
                                                 type_html.push(
                                                    <div className={"row addnew textbox-"+record.id+"-"+t} id={"textbox-"+record.id+"-"+t}>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="input-group">
                                                            <input type="text" placeholder="ADA#" name="convensionType" data-inputmask="'mask': '999 99 9999'"  className="" />
                                                            </div>
                                                            {
                                                                (record.id === 18)
                                                                ?
                                                                <div style={{display:'none'}} className="input-group-append" data-toggle="tooltip" data-placement="top" title="Check Membership"><span className="input-group-text"><i className="glyphicon glyphicon-user"></i>Check</span></div>
                                                                :
                                                                ''
                                                            }
                                                            <div></div>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="form-group">
                                                            <input type="text" name="convensionType"  placeholder="Full Name" required/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="form-group">
                                                            <input type="email" name=""  className="input-change-required" placeholder="Email" required/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 col-xs-2 p-0">
                                                            <button type="button" className="removebutton" onClick={e => removeDiv(record.id, t, index)} ><i className="far fa-times-circle"></i></button>
                                                        </div>
                                                    </div>
                                                 );
                                            } else if(record.id === 20) {
                                                 type_html.push(
                                                    <div className={"row addnew textbox-"+record.id+"-"+t} id={"textbox-"+record.id+"-"+t}>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="input-group">
                                                            <input type="text" className="hygin_name 20_input form-control" name="convensionType"  placeholder="Full Name" required />
                                                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Check Membership"><span className="input-group-text"><i className="glyphicon glyphicon-user"></i>Check</span>
                                                                </div>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="form-group">
                                                            <input type="number" name="convensionType"  placeholder="ID#" data-inputmask="'mask': '999999'" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 col-xs-5">
                                                            <div className="form-group">
                                                                <input type="email" name="convensionType" className="input-change-required" placeholder="Email" required/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 col-xs-2 p-0">
                                                            <input type="text" className="20_amount" name="convensionType" readOnly  placeholder="Amount" style={{display: 'none'}} />
                                                            <button type="button" className="removebutton" onClick={e => removeDiv(record.id, t, index)} ><i className="far fa-times-circle"></i></button>
                                                        </div>
                                                    </div>
                                                 )
                                            } else if(record.id === 21) {
                                                type_html.push(
                                                    <div className={"row addnew textbox-"+record.id+"-"+t} id={"textbox-"+record.id+"-"+t}>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <div className="form-group">
                                                            <input type="text" name="convensionType"  placeholder="Full Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12" >
                                                            <div className="form-group">
                                                            <input type="email" name="convensionType"  className="input-change-required" placeholder="Email" required/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12 p-0" >
                                                            <button type="button" className="removebutton" onClick={e => removeDiv(record.id, t, index)}  ><i className="far fa-times-circle"></i></button>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                
                                            }
                                            
                                        }
                                        return type_html;
                                      }
                                      )()
                                    }
                                  
                            </div>

                            <div className="clearfix"></div>

                            <br />
                          
                      </div>

                      {
                          (record.id === 20)
                          ?
                           <div className="new-bg sp" style={{paddingBottom:'10px'}}>
                                <div style={{fontSize:'16px'}} className="row text-center alert alert-info">
                                    2022 Pricing Only Celebrating our New Dental Hygienist Affiliate Membership If you would like to join, please follow the following link:
                                    <a target="_new" href="https://ebusiness.ada.org/productcatalogPo3/100877/UT0006FB5F4SW43/UTeCatalog/UDA-2022-Dental-Hygienist-Affiliate-Membership/">https://ebusiness.ada.org/productcatalogPo3/100877/UT0006FB5F4SW43/UTeCatalog/UDA-2022-Dental-Hygienist-Affiliate-Membership/</a>

                                </div>
                                 <div className="clearfix"></div>
                            </div>
                          :
                          (record.id === 18)
                          ?
                           <div className="new-bg sp" style={{paddingBottom:'10px'}}>
                                <div style={{fontSize:'16px'}} className="row text-center alert alert-info">
                                    If you would like to join on UDA Membership, please follow the following link:
                                    <a target="_new" href="https://www.uda.org/member-center/join-renew/join-renew-now/">https://www.uda.org/member-center/join-renew/join-renew-now/</a>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                          :
                          ''
                      }

                    </div>
                  ))
                   
                }

                <div className="new-bg sp">
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <h4 className="new-title" style={{display:'none'}}>Test</h4>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group bt-down">
                                <div className="grand">Grand Total ($)</div>
                            </div>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group mint">
                                <input 
                                type="text"
                                className="closetotgrand"
                                placeholder="Total"
                                value={totalgrand}
                                readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-submit m-b-20 force-submit">
                        <span className="error"></span>
                        <br />
                        <input 
                        type="reset" 
                        value="Reset All" 
                        className="submit" 
                        id="reset"
                        name="reset" />
                        <button type="submit" defaultValue="Add" className="submit submit_button" name="submit" id="submit">Proceed</button>
                    </div>

                </div>

            </form>
        </div>
    )
   
}

export default SpringForm;