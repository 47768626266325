import React,{ useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../images/uda-logo.png";
import logo_blue from "../images/uda-logo-blue.png";

function ConventionTransaction(){

    useEffect(() => {
        document.title = "SPRING REGISTRATION DETAILS"
        window.scrollTo(0, 0)
    }, []);

    // const print = () => { 
    //     var content = document.getElementById('printable');console.log(content)
    //     var pri = document.getElementById('ifmcontentstoprint').contentWindow;
    //     pri.document.open();
    //     pri.document.write(content.innerHTML);
    //     pri.document.close();
    //     pri.focus();
    //     pri.print();

    // }

    const printDiv = (divName) => {
        var printContents = document.getElementById('printable').innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    }

    return (
        <div className="signup-form col-sm-10 col-centered">
            <form>
                <h2>
                    <Link to="convention-on-transaction">
                      <img src={logo} alt="" />
                    </Link>
                    SPRING REGISTRATION DETAILS
                </h2>
                <div className="new-bg">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <div className="form-wizard" style={{marginBottom:'0px'}}>
                                <div className="form-wizard-header">
                                    <ul className="list-unstyled form-wizard-steps clearfix" style={{marginBottom:'0px'}}>
                                        <li className="activated">
                                            <span className="large-src"> 
                                                <svg viewBox="-27 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m146 492c0 11.046875-8.953125 20-20 20h-46c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h245.890625c44.109375 0 80 35.886719 80 80v119c0 11.046875-8.957031 20-20 20-11.046875 0-20-8.953125-20-20v-119c0-22.054688-17.945313-40-40-40h-245.890625c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h46c11.046875 0 20 8.953125 20 20zm179.886719-352c0-11.046875-8.953125-20-20-20h-206c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h206c11.046875 0 20-8.953125 20-20zm-79.886719 80c0-11.046875-8.953125-20-20-20h-126.113281c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h126.113281c11.046875 0 20-8.953125 20-20zm-146.113281 60c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h83.113281c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20zm348.921875 217.121094c-7.695313 9.457031-19.050782 14.878906-31.15625 14.878906h-195.304688c-12.171875 0-23.5625-5.417969-31.25-14.863281-7.734375-9.503907-10.738281-21.851563-8.242187-33.875 6.460937-31.125 23.574219-59.445313 48.183593-79.746094 9.320313-7.6875 19.5625-14.085937 30.421876-19.085937-12.101563-13.746094-19.460938-31.757813-19.460938-51.464844 0-43.011719 34.988281-78 78-78s78 34.988281 78 78c0 19.707031-7.359375 37.71875-19.460938 51.464844 10.859376 5 21.101563 11.394531 30.421876 19.085937 24.117187 19.894531 41.03125 47.492187 47.78125 77.878906.15625.527344.289062 1.058594.402343 1.601563 2.507813 12.09375-.53125 24.53125-8.335937 34.125zm-166.808594-184.15625c0 20.953125 17.046875 38 38 38s38-17.046875 38-38-17.046875-38-38-38-38 17.046875-38 38zm135.988281 158.46875c-.003906-.011719-.007812-.027344-.007812-.039063-9.605469-46.253906-50.8125-79.828125-97.980469-79.828125s-88.378906 33.574219-97.980469 79.828125c-.089843.449219.21875.59375.21875.597657.007813 0 .042969.007812.109375.007812h195.304688c.011718-.011719.28125-.214844.335937-.566406zm0 0"></path>
                                                </svg>
                                            </span> 
                                            <span className="sml-src" style={{display:'none'}}>1</span>
                                            <p>Details</p>
                                        </li>
                                        <li className="activated">
                                           <span className="large-src">
                                               <svg viewBox="0 -68 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m412 232.121094c11.046875 0 20 8.953125 20 20v24c0 11.046875-8.953125 20-20 20h-46c-11.046875 0-20-8.953125-20-20v-24c0-11.046875 8.953125-20 20-20zm60-36v-60h-432v159.878906c0 22.054688 17.945312 40 40 40h352c22.054688 0 40-17.945312 40-40 0-11.046875 8.953125-20 20-20s20 8.953125 20 20c0 44.113281-35.886719 80-80 80h-352c-44.113281 0-80-35.886719-80-80v-216c0-44.113281 35.886719-80 80-80h352c44.113281 0 80 35.886719 80 80v116.121094c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20zm0-100v-16.121094c0-22.054688-17.945312-40-40-40h-352c-22.054688 0-40 17.945312-40 40v16.121094zm-372 80c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h165c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20zm0 0"></path>
                                                </svg> 
                                           </span>
                                           <span className="sml-src" style={{display:'none'}}>2</span>
                                           <p>Review &amp; Payment</p>
                                        </li>
                                        <li className="active">
                                            <span className="large-src">
                                                 <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{background:'new 0 0 512 512'}} space="preserve">
                                                        <g>
                                                            <g>
                                                            <g>
                                                                <path d="M468.04,91.687l-205-90c-5.147-2.26-11.007-2.249-16.146,0.029l-203,90C36.663,94.922,32,102.089,32,110v158
                                                                                c0,59.712,23.597,120.752,64.741,167.468c20.695,23.498,44.553,42.092,70.912,55.266C195.887,504.845,225.611,512,256,512
                                                                                c42.552,0,83.967-13.773,119.769-39.829c8.931-6.5,10.902-19.009,4.402-27.94c-6.5-8.931-19.009-10.901-27.94-4.402
                                                                                C323.313,460.875,290.037,472,256,472c-96.299,0-184-97.234-184-204V123.01l183.04-81.15L440,123.062V268
                                                                                c0,36.829-10.58,74.1-30.597,107.782c-5.643,9.496-2.52,21.768,6.976,27.411c9.497,5.643,21.768,2.52,27.411-6.976
                                                                                C467.479,356.354,480,312.018,480,268V110C480,102.064,475.307,94.878,468.04,91.687z"></path>
                                                                <path d="M351.385,157.503L227.379,312.451l-69.515-66.865c-7.961-7.657-20.622-7.412-28.279,0.549
                                                                                c-7.657,7.961-7.412,20.622,0.549,28.279l70.485,67.798c0.028,0.027,0.057,0.054,0.085,0.082
                                                                                c7.299,6.939,16.746,10.707,26.754,10.707c0.537,0,1.074-0.011,1.612-0.033c10.601-0.426,20.337-5.015,27.415-12.919
                                                                                c0.246-0.275,0.484-0.557,0.715-0.845l125.415-156.708c6.902-8.624,5.505-21.21-3.118-28.112
                                                                                C370.872,147.482,358.287,148.878,351.385,157.503z"></path>
                                                            </g>
                                                            </g>
                                                        </g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        </svg>
                                            </span>
                                            <span className="sml-src" style={{display:'none'}}>3</span>
                                            <p>Confirmation</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group">
                                <h3 className="your_info">Your Transaction Info</h3>
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Transaction ID :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Transaction ID" 
                                 value="0" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                           <div className="form-group">
                                <label>Transaction Reference No :</label>
                                <input 
                                 type="text" 
                                 name="Reference No" 
                                 placeholder="Reference No" 
                                 value="1640062013" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Amount ($) :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Amount" 
                                 value="195" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Transaction Status :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Status" 
                                 value="Success" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Transaction Date :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Date" 
                                 value="12-20-2021 09:47:14 PM" 
                                 disabled
                                 />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="new-bg">
                    <div className="row">
                       <div className="col-sm-12 col-xs-12">
                            <div className="form-group">
                                <h3 className="your_info">Your Personal Info</h3>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Practice Name :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Practice Name" 
                                 value="Test Practice" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>First Name :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="First Name" 
                                 value="Yabase" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Last Name :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Last Name" 
                                 value="James" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Phone :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Phone" 
                                 value="(897) 987-9879" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Email :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Email" 
                                 value="james@hexagonsupport.com" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-8 col-xs-12">
                            <div className="form-group">
                                <label>Address :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Address" 
                                 value="Test address" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>City :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="City" 
                                 value="Utah" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>State :</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="State" 
                                 value="Utah" 
                                 disabled
                                 />
                            </div>
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <div className="form-group">
                                <label>Zip:</label>
                                <input 
                                 type="text" 
                                 name="name" 
                                 placeholder="Zip Code" 
                                 value="876543" 
                                 disabled
                                 />
                            </div>
                        </div>

                    </div>

                    <div className="row"></div>
                </div>

                <div className="new-bg">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <h3 algin="center">Spring Workshops</h3>
                        </div>
                    </div>
                </div>

                <div className="new-bg sp">
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <h4 className="new-title">UDA MEMBER DENTIST NAME ($95)</h4>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group bt-down">
                               <input  className="input2" name="Number" value="1" disabled />
                            </div>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group mint">
                              <input type="text" className="output" name="Number" value="$95" disabled />
                            </div>
                        </div>
                    </div>

                    <div className="new-border"></div>

                    <div id="text-box-18">
                        <div className="row addnew">
                            <div className="col-sm-4 col-xs-5">
                                <div className="form-group">
                                    <input disabled type="text" name="" value="test" />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-5">
                                <div className="form-group">
                                    <input disabled type="text" name="" value="test@gmail.com" />
                                </div>
                            </div>
                            <div className="col-sm-3 col-xs-5">
                                <div className="form-group">
                                    <input disabled type="text" name="" value="322 34 2342" />
                                </div>
                            </div>
                            <div className="col-sm-1 col-xs-5"></div>
                        </div>
                    </div>

                    <div className="clearfix"></div>
                    <br />

                </div>

                <div className="new-bg sp">
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <h4 className="new_title">NON-UDA AFFILIATE HYGIENIST NAME ($100)</h4>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group bt-down">
                                <input id="options" className="input2" name="Number" value="1" disabled />
                            </div>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group mint">
                               <input type="text" className="output" name="Number" value="$100" disabled />
                            </div>
                        </div>
                    </div>

                    <div className="new-border"></div>

                    <div id="text-box-21">
                        <div className="row addnew">
                            <div className="col-sm-4 col-xs-12">
                                <div className="form-group">
                                    <input disabled type="text" name="" value="Test" />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div className="form-group">
                                    <input disabled type="email" name="" value="test@gmail.com" />
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12"></div>
                        </div>
                    </div>

                    <div className="clearfix"></div>
                    <br />

                </div>

                <div className="new-bg sp">
                    <div className="new-border"></div>
                    <div className="row">
                        <div className="col-sm-8 col-xs-12">
                            <h4 className="new-title" style={{display:'none'}}>Test</h4>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group bt-down">
                               <div className="grand">Grand Total</div>
                            </div>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <div className="form-group mint">
                                <input type="text" className="closetotgrand" value="$195" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="new-border"></div>
                    <div className="clearfix"></div>
                    <br />
                    <div className="form-submit m-b-20">
                        <Link to="/spring-form">
                           <button 
                             type="button" 
                             className="submit btn-primary preview_submit regagin"
                             >
                                 Register Another
                            </button>
                        </Link>
                        <button 
                          type="button" 
                          className="submit preview_submit" 
                          name="submit" 
                          id="submit" 
                          onClick={() => printDiv('printMe')}
                          
                          >
                              Print
                        </button>
                    </div>
                </div>
                

                {/* <iframe id="ifmcontentstoprint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}>
                </iframe>  */}

                <div id="printable" style={{display:'none'}}>
                    <div className="page" 
                    style={{width: 'auto',minHeight: '297mm',padding: '10mm',margin: '10mm auto',border: '1px #D3D3D3 solid',borderRadius: '5px',background: 'white',boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)'}}
                    >
                        <div className="subpage">
                            <img src={logo_blue} alt="" style={{float:'left',marginBottom:'20px'}}></img>
                            <div style={{float:'right',textAlign:'center'}}>
                                <h4><strong style={{fontSize:'17px'}}>Transaction Details</strong></h4>
                                <p>12/20/2021 09:47:14 PM</p>
                            </div>
                            <div className="clearfix"></div>
                            <div style={{width: '50%', minHeight: '150px', float: 'left', border: '1px solid #ddd',padding: '15px', marginTop: '20px'}}>
                                <h4 style={{paddingLeft: '20px', fontSize: '20px'}}><strong>User Details :</strong></h4>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Practice Name :</strong> Practice Name</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Name :</strong> Yabase James</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Phone :</strong> 9994374673</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>E-Mail:</strong> test@email.com</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Address :</strong> Address, City, State </p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Zip Code :</strong> 876543</p>
                            </div>
                            <div style={{width: '50%', minHeight: '150px', float: 'right', border: '1px solid #ddd',padding: '15px', marginTop: '20px'}}>
                                <h4 style={{paddingLeft: '20px', fontSize: '20px'}}><strong>Transaction Details :</strong></h4>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Transaction ID :</strong> 0 </p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Transaction Reference No :</strong> 1234567890</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Amount ($) :</strong> 195</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Transaction Status :</strong> Success </p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>Transaction Date :</strong> 12-20-2021 09:47:14 PM</p>
                                <p style={{marginBottom: '5px',paddingLeft: '20px'}}><strong>&nbsp;</strong></p>
                            </div>
                            
                            <div className="clear"></div>

                            {/* <page size="A4" layout="portrait" style={{padding:'0px !important',marginTop:'10px'}}> */}
                                <div className="clear"></div>
                                <table style={{width:'100%'}}>
                                    <tbody className="pnt">
                                        <tr>
                                          <th colSpan="12"><h4>UDA - Spring Registration</h4></th>
                                        </tr>
                                        <tr>
                                            <th>SL NO</th>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>ADA Number</th>
                                            <th>Fee</th>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">UDA MEMBER DENTIST NAME ($95)</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>test</td>
                                            <td>test@gmail.com</td>
                                            <td>322 34 2342</td>
                                            <td>$95</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3"></td>
                                            <td>Total</td>
                                            <td>$95</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">NON-UDA AFFILIATE HYGIENIST NAME ($100)</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>test</td>
                                            <td>test@gmail.com</td>
                                            <td>322 34 2342</td>
                                            <td>$100</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td>Total</td>
                                            <td>$195</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clear"></div>
                                <p style={{textAlign: 'center', marginTop: '20px'}}>*Copyright © 2022 Utah Dental Association. Reproduction or republication strictly prohibited without prior written permission.</p>
                            {/* </page> */}
                        </div>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default ConventionTransaction;